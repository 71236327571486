var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1500px","persistent":"","scrollable":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{staticClass:"px-6 pt-16 pb-8",attrs:{"height":"100%"}},[_c('v-data-table',{staticClass:"mt-6 elevation-4 my-table",attrs:{"headers":_vm.opportunityHeaders,"items":_vm.item,"hide":"","hide-default-footer":""}}),_c('v-data-table',{staticClass:"mt-6 elevation-4 my-table",attrs:{"headers":_vm.descriptionHeader,"items":_vm.item,"hide-default-footer":""}}),_c('div',{staticClass:"elevation-4 mt-6 pa-6",attrs:{"justify":"start"}},[_c('h3',{staticClass:"mb-10 title font-weight-bold"},[_vm._v(" Select one of the volunteering's available dates: ")]),_c('v-data-table',{attrs:{"headers":_vm.headersTable,"items":_vm.item[0].eventDays,"checkboxColor":"primary","hide-default-footer":"","show-select":""},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.startHour)+" - "+_vm._s(item.endHour))])]}}],null,true),model:{value:(_vm.datesSelected),callback:function ($$v) {_vm.datesSelected=$$v},expression:"datesSelected"}}),_c('v-row',{staticClass:"pl-10 mt-6"},[_c('v-card-subtitle',{staticClass:"font-weight-bold mr-4"},[_vm._v(" Current Available Hours: "),_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v(_vm._s(_vm.profile.daysOff.availableHours))])])],1),_c('v-row',{staticClass:"pl-10 mt-6"},[_c('v-card-subtitle',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.addedHours > 0 && _vm.profile.daysOff.availableHours >= _vm.addedHours
          ),expression:"\n            addedHours > 0 && profile.daysOff.availableHours >= addedHours\n          "}],staticClass:"font-weight-bold"},[_vm._v(" This application will consume "),_c('span',{staticClass:"primary--text font-weight-bold text-decoration-underline"},[_vm._v(" "+_vm._s(_vm.addedHours))]),_vm._v(" from your total available hours. ")]),_c('v-card-subtitle',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.addedHours > 0 && _vm.profile.daysOff.availableHours < _vm.addedHours
          ),expression:"\n            addedHours > 0 && profile.daysOff.availableHours < addedHours\n          "}],staticClass:"font-weight-bold error--text"},[_vm._v(" You don't have enough available hours for this application. ")])],1)],1),_c('v-card-actions',{staticClass:"d-flex direction-row justify-center mt-6"},[_c('v-btn',{staticClass:"px-14 ma-4 font-weight-bold white--text",attrs:{"disabled":!_vm.datesSelected.length ||
          (_vm.addedHours > 0 && _vm.profile.daysOff.availableHours < _vm.addedHours),"color":"primary","large":""},on:{"click":_vm.apply}},[_vm._v(" Apply ")]),_c('v-btn',{staticClass:"px-14 ma-4 font-weight-bold",attrs:{"color":"error","large":"","outlined":""},on:{"click":_vm.closeDialog}},[_vm._v(" Cancel ")])],1),_c('backgroundCheckNotify',{attrs:{"isOpen":_vm.isBackgroundCheck},on:{"confirmRequest":_vm.submit,"closeDialog":function($event){_vm.isBackgroundCheck=false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }