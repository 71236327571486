<template>
  <v-dialog v-model='open' max-width='1500px' persistent scrollable>
    <v-card class='px-6 pt-16 pb-8' height='100%'>
      <v-data-table
        :headers='opportunityHeaders'
        :items='item'
        class='mt-6 elevation-4 my-table'
        hide
        hide-default-footer
      >
      </v-data-table>
      <v-data-table
        :headers='descriptionHeader'
        :items='item'
        class='mt-6 elevation-4 my-table'
        hide-default-footer
      ></v-data-table>
      <div class='elevation-4 mt-6 pa-6' justify='start'>
        <h3 class='mb-10 title font-weight-bold'>
          Select one of the volunteering's available dates:
        </h3>
        <v-data-table
          v-model='datesSelected'
          :headers='headersTable'
          :items='item[0].eventDays'
          checkboxColor='primary'
          hide-default-footer
          show-select
        >
          <template v-slot:[`item.time`]='{ item }'>
            <span>{{ item.startHour }} - {{ item.endHour }}</span>
          </template>
        </v-data-table>
        <v-row class='pl-10 mt-6'>
          <v-card-subtitle class='font-weight-bold mr-4'>
            Current Available Hours:
            <span class='font-weight-bold primary--text'>{{
                profile.daysOff.availableHours
                                                         }}</span></v-card-subtitle
          >
        </v-row>
        <v-row class='pl-10 mt-6'>
          <v-card-subtitle
            v-show='
              addedHours > 0 && profile.daysOff.availableHours >= addedHours
            '
            class='font-weight-bold'
          >
            This application will consume
            <span
              class='primary--text font-weight-bold text-decoration-underline'
            >
              {{ addedHours }}</span
            >
            from your total available hours.
          </v-card-subtitle>
          <v-card-subtitle
            v-show='
              addedHours > 0 && profile.daysOff.availableHours < addedHours
            '
            class='font-weight-bold error--text'
          >
            You don't have enough available hours for this application.
          </v-card-subtitle>
        </v-row>
      </div>
      <v-card-actions class='d-flex direction-row justify-center mt-6'>
        <v-btn
          :disabled='
            !datesSelected.length ||
            (addedHours > 0 && profile.daysOff.availableHours < addedHours)
          '
          class='px-14 ma-4 font-weight-bold white--text'
          color='primary'
          large
          @click='apply'
        >
          Apply
        </v-btn>
        <v-btn
          class='px-14 ma-4 font-weight-bold'
          color='error'
          large
          outlined
          @click='closeDialog'
        >
          Cancel
        </v-btn>
      </v-card-actions>
      <backgroundCheckNotify :isOpen="isBackgroundCheck" @confirmRequest="submit" @closeDialog="isBackgroundCheck=false"/>
    </v-card>
  </v-dialog>
</template>

<script>
import employeeProfile from '../../../services/employeeProfile'
import backgroundCheckNotify from '../../../components/dialogs/background-check-notify'

export default {
  props: ['open', 'item', 'dataTable'],
  components: {backgroundCheckNotify},
  created() {
    this.employeeId = this.$store.getters.user.profile_id.employee_id
    this.getEmployeeProfile(this.employeeId)
  },
  updated() {
    if (this.open) {
      this.membersCount = this.item[0].membersCount
      this.volunteersRequired = this.item[0].volunteersRequired
    }
  },
  data: () => ({
    isBackgroundCheck:false,
    selected: [],
    headersTable: [
      {
        text: 'Description',
        align: 'start',
        sortable: true,
        value: 'description'
      },
      {text: 'Date', value: 'date', sortable: 'true'},
      {
        text: 'Hs.',
        sortable: true,
        value: 'hours'
      },
      {text: 'Time', value: 'time'},
      {text: 'Address', value: 'address'},
      {text: 'Spots', value: 'volunteersRequired'}
    ],
    opportunityHeaders: [
      {
        text: 'Need',
        align: 'start',
        sortable: true,
        value: 'need',
        divider: true
      },
      {
        text: 'Volunteers Req.',
        align: 'start',
        sortable: true,
        value: 'volunteersRequired',
        divider: true
      },
      {
        text: 'Hours Per Volunteer',
        align: 'start',
        sortable: true,
        value: 'hoursRequired',
        divider: true
      },
      {
        text: 'Frequency',
        align: 'start',
        sortable: true,
        value: 'frequency',
        divider: true
      },
      {
        text: 'Hours Range',
        align: 'start',
        sortable: true,
        value: 'time',
        divider: true
      },
      {
        text: 'Start',
        align: false,
        sortable: false,
        value: 'start',
        divider: true
      },
      {
        text: 'End',
        align: false,
        sortable: false,
        value: 'end',
        divider: true
      }
    ],
    descriptionHeader: [
      {
        text: 'Description',
        align: 'start',
        sortable: false,
        value: 'description'
      }
    ],
    membersCount: Number,
    volunteersRequired: Number,
    employeeId: Number,
    availableDates: [],
    datesSelected: [],
    requestItem: {},
    requested: false,
    addedHours: 0,
    profile: {
      daysOff: {
        availableHours: 0
      }
    }
  }),
  methods: {
    async getEmployeeProfile(employeeId) {
      try {
        const profile = await employeeProfile.getEmployeeProfile(employeeId)

        this.profile.daysOff.availableHours = profile.daysOff.availableHours
      } catch (err) {
        console.log(err)
      }
    },
    closeDialog() {
      this.datesSelected = []
      this.$emit('close')
    },
    async apply() {
      this.item[0].backgroundCheck 
       ? this.isBackgroundCheck = true
       : await this.submit()
    },
    async submit() {
      this.employeeId = this.$store.getters.user.profile_id.employee_id
      let selectedId = this.datesSelected.map(el => el.id)
      this.$emit('apply', {
        eventId: selectedId,
        employeeId: this.employeeId
      })
      this.closeDialog()
    },
    addHours(event, items) {
      const eventValue = event
      let eventIds = []
      eventValue.forEach((el) => {
        eventIds.push(el)
      })
      if (eventIds.includes(items.id)) {
        this.addedHours += items.hours
      } else {
        this.addedHours -= items.hours
      }
    }
  }
}
</script>
<style lang='scss'>
.bordered {
  border: 3px solid #e8e8e8;
  border-radius: 15px;
}
.title {
  color: #757575;
}
</style>

<style lang='scss' scoped>
.my-table.v-data-table :deep(th) {
  font-size: 18px !important;
  font-weight: bold;
  color: #757575 !important;
}
</style>
